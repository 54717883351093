export const DefaultState = () => ({
    firmDetailList: [
        {
            label: "PAN Card",
            value: "",
            imgClass: "pan",
            mappingKey: 'panNo'
        },
        {
            label: "GSTIN Number",
            value: "",
            imgClass: "gstin",
            mappingKey: 'gstin'
        },
        {
            label: "Established in",
            value: "",
            imgClass: "established",
            mappingKey: 'yearOfEstablishment'
        },
        {
            label: "Firm Type",
            value: "",
            imgClass: "firm-type",
            mappingKey: 'firmType'
        },
        {
            label: "Shop Area",
            value: "",
            measure: 'sq ft',
            imgClass: "shop-area",
            mappingKey: 'shopArea'
        },
        {
            label: "Shop Ownership",
            value: "",
            imgClass: "shop-ownership",
            mappingKey: 'ownership'
        }
    ],
    firmAddress: {
        line: "",
        city: {
            name: ""
        },
        state: {
            name: ""
        },
        pinCode: {
            code: ""
        }
    },
    businessDetailList: [
        {
            id: "Current Turnover",
            value: "",
            measure: ' (Rs)',
            mappingKey: 'turnover'
        },
        {
            id: "Current Manpower",
            value: "",
            measure: 'People',
            mappingKey: 'manPower'
        },
        {
            id: "Delivery Vehicle",
            value: "",
            mappingKey: 'noOfDeliveryVehicle'
        }
    ],
    uploadedDocuments: [],
    warehouseDetails: {},
    sliderSettings: {
        dots: false,
        slidesToScroll: 1,
        infinite: true,
        variableWidth: true
    },
    bankDetails: [],
    totalBanks: 0,
    partnerDetails: [],
    existingPartnerShipsDetails: [],
    activityList: [],
    appliedPartnershipDetails: {},
    pendingDays:'',
    createdDate: '',
    ticketAssignedTo:'',
    ticketAssignedToRole:'',
    pendingDocuments:'',
    totalDocuments:'',
});

export const Mapper = (data, currentForm) => {
    const {
        id,
        firm,
        contactPerson: {email, mobileNumber},
        firm: {
            name,
            yearOfEstablishment,
            banks,
            address,
            wareHouse,
            warehouseAvailable
        },
        businessDetail,
        partnerDetails,
        existingPartnerShips,
        appliedPartnership,
        partnerDocument,
        allActivity,
        pendingDays,
        createdDate,
//        allActions,
        assignTo,
        pendingDocuments,
        totalDocuments,
        applicationId
    } = data;

    const {firmDetailList} = currentForm;
    let updatedFirmDetailsList = firmDetailList && firmDetailList.length && firmDetailList.map(item => (
        {
            ...item,
            value: firm[item.mappingKey]
        }
    ));

    const {businessDetailList} = currentForm;
    let updatedBusinessDetailList = businessDetailList && businessDetailList.length && businessDetailList.map(item => (
        {
            ...item,
            value: businessDetail[item.mappingKey]
        }
    ));

    let updatedBankDetails = [...banks];

    const totalBanks = [...banks].length;

    let updatedPartnerDetails = [...partnerDetails];

    const partnerCounts = [...partnerDetails].length;

    let updatedWarehouseDetails = warehouseAvailable ? {...wareHouse} : {};

    let updatesExistingPartnerDetails = [...existingPartnerShips];

    let updatedAppliedPartnership = {...appliedPartnership};

    let updatedUploadedDocuments = [];
    if(partnerDocument && partnerDocument.uploadedDocuments && partnerDocument.uploadedDocuments.length){
        updatedUploadedDocuments = [...partnerDocument.uploadedDocuments];
    }

    let updatedFirmAddress = {...address};

    currentForm.firm = {
        ...currentForm.firm
    };

        let activityList = [];
        isValidArray(allActivity) &&
            allActivity.sort(function(a, b) {
            return b.id - a.id  ;
            })
            .map((item, index)=>{
                    let activityItem = {
                        date: item.createdDate || '',
                        assignedBy: item.sourceRoleName || '',
                        assignedTo: item.targetRoleName || '',
                        actionName: item.actionName || '',
                        isAssignedBack: false,
                        comments: item.comment || '',
                        docComment: item.documentComment && JSON.parse(item.documentComment) || [],
                    };
                    return activityList.push(activityItem);
                });

                let ticketAssignedTo = (assignTo && assignTo.name) || '';
                let ticketAssignedToRole = (assignTo && assignTo.role) || '';
                let requestApplicationId = (applicationId && applicationId.applicationId) || '';

currentForm = {
        ...currentForm,
        requestId: requestApplicationId,
        firmName: name,
        firmAddress: {...updatedFirmAddress},
        email,
        mobileNumber,
        establishmentYear: yearOfEstablishment,
        firmDetailList: [...updatedFirmDetailsList],
        bankDetails: [...updatedBankDetails],
        totalBanks,
        businessDetailList: [...updatedBusinessDetailList],
        warehouseDetails: {...updatedWarehouseDetails},
        partnerDetails: [...updatedPartnerDetails],
        partnerCounts,
        existingPartnerShipsDetails: [...updatesExistingPartnerDetails],
        appliedPartnershipDetails: {...updatedAppliedPartnership},
        uploadedDocuments: [...updatedUploadedDocuments],
        activityList: [...activityList],
        pendingDays:pendingDays || '',
        createdDate: createdDate || '',
        allActions: data.allActions,
        ticketAssignedTo: ticketAssignedTo,
        ticketAssignedToRole: ticketAssignedToRole,
        pendingDocuments:pendingDocuments || '',
        totalDocuments:totalDocuments || '',
    };

    return currentForm;
};

export const isValidArray = (arr) => {
    return Array.isArray(arr) && arr.length;
};

export const isEmpty = (obj) => {
    return JSON.stringify(obj) === '{}';
};
