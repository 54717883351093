import React, {Component, Fragment} from "react";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import {commonService} from "../../../../storeManager/common/services";
import {PATHS, ROLES_CATEGORY} from "../../../../constants";
import Input from "../../../Common/Input";
import FormValidator from '../../../../services/FormValidator';
import {withRouter} from 'react-router-dom';
import Loader from "../../../Loader";
import {isAuthenticated} from "../../../../helper";
// import ReCaptcha from "../../../Common/GoogleCaptcha/googleCaptcha";
import { interceptedData } from "../../../../services/commonUtils";

class VerifyOTP extends Component {
    state = {
        loginOtpForm: {
            otp: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'otp'],
                labelConfig: {
                    label: 'otp',
                    classes: ['floating-label'],
                },
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'otp',
                    maxLength: 6,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
                resendOtp: true
            }
        },
        error: '',
        isLoading: false
    };

    captchaElement = React.createRef();

    // onCaptchaResolved = (captchaToken) => {
    //     (captchaToken && this.validateLoginFromServer(captchaToken));
    // };

    // onValidateCaptcha = () => {
    //     window.grecaptcha && window.grecaptcha.reset();
    //     this.captchaElement.execute(this.captchaElementResetId); // this.captchaElementResetId
    // }

    inputChangedHandler = (inputValue, inputIdentifier) => {
        const {loginOtpForm} = this.state;
        const updatedForm = {...loginOtpForm};
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let value = inputValue;
        if(inputIdentifier === 'otp') {
            value = value.replace(/[^0-9]/g, '');
        }
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.errorMessage = '';
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({loginOtpForm: updatedForm, error: ''})
    };

    validateLoginOTP = (e) => {
        e.preventDefault();
        const { loginOtpForm } = this.state;
        const validateObj = {};
        const formKeys = Object.keys(loginOtpForm);
        formKeys.forEach((key) => {
            validateObj[key] = loginOtpForm[key].value;
        });
        const formStatus = FormValidator.validateLoginOTPForm(validateObj);
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...loginOtpForm };
            formKeys.forEach((key) => {
                const inputKey = `${key}Message`;
                const errorMessage = errorMessages[inputKey];
                if (errorMessage && errorMessage !== '') {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = errorMessage;
                }
            });
            this.setState({ loginOtpForm: updatedForm, formErrorMessage: '' });
        } 
        // else {
        //     this.onValidateCaptcha();
        // }
    };

    validateLoginFromServer = async (captchaToken) => {
        const {mobileNumber, history} = this.props;
        const { loginOtpForm } = this.state;
        const validateObj = {};
        const formKeys = Object.keys(loginOtpForm);
        formKeys.forEach((key) => {
            validateObj[key] = loginOtpForm[key].value;
        });
        const payload = {
            mobileNumber: mobileNumber,
            otp: validateObj.otp,
            service: "LOGIN",
            // captchaToken
        };
        this.setState({ isLoading: true });
        if(!isAuthenticated()) {
            try {
                const response = await commonService.validateLoginOTP(payload);
                const responseData = interceptedData(response.data);
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    const loginToken = responseData.data.token;
                    localStorage.setItem('token', `Bearer ${loginToken}`);
                    localStorage.setItem('role', JSON.stringify(responseData.data.role));
                    localStorage.setItem('name', responseData.data.name);
                    localStorage.setItem('emailId', responseData.data.emailId);
                    localStorage.setItem('mobileNumber', responseData.data.mobileNumber);
                    localStorage.setItem('roleCategory', JSON.stringify(responseData.data.roleCategory));
                    localStorage.setItem('partners', JSON.stringify(responseData.data.partners));
                    localStorage.setItem('showDashboard', responseData.data.showDashboard);

                    history.push(PATHS.HOME);
                }
                else {
                    this.setState({error: responseData.message, isLoading: false})
                }
            } catch (e) {
                if (e.response && e.response.status === 429) {
                    this.setState({ error: "You have exceeded the number of attempts. Please try after sometime.", isLoading: false });
                } else {
                    console.log(e);
                    this.setState({ error: GlobalConfig.serverError, isLoading: false });
                }
            }
        } else {
            this.setState({ isLoading: false });
        }

    };

    reGenerateOtp = async () => {
        const { mobileNumber } = this.props;
        this.setState({isLoading: true, error: ''});
        let payload = {service: "LOGIN", mobileNumber};
        try {
            const response = await commonService.generateOtp(payload);
            const responseData = interceptedData(response.data);
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false})
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch (e) {
            if (e.response && e.response.status === 429) {
                this.setState({ error: "You have exceeded the number of attempts. Please try after sometime.", isLoading: false });
            } else {
                console.log(e);
                this.setState({ error: GlobalConfig.serverError, isLoading: false });
            }
        }
    };

    render() {
        const {loginOtpForm, error ,isLoading} = this.state;
        const {validateLoginOTP} = this;
        const {mobileNumber, openOTP} = this.props;
        let formElementsArray = [];
        const formKeys = Object.keys(loginOtpForm);
        formKeys.forEach((key) => {
            formElementsArray.push({
                id: key,
                config: loginOtpForm[key],
            });
        });
        return (
            <Fragment>
                {
                    isLoading && <Loader/>
                }
                <div className="heading pb10">
                    Verify Number
                </div>
                <div className="description pb25">
                    Enter 6 digit code sent to your phone
                    <span className="value">
                        {mobileNumber}
                    </span>
                </div>
                <form onSubmit={validateLoginOTP}>
                    {
                        formElementsArray && formElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                labelConfig={formElement.config.labelConfig}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                value={formElement.config.value}
                                valid={formElement.config.valid}
                                errorMessage={formElement.config.errorMessage}
                                touched={formElement.config.touched}
                                resendOtp={formElement.config.resendOtp}
                                reGenerateOtp={()=>this.reGenerateOtp()}
                                wrapperClasses={formElement.config.wrapperClasses}
                                inputGroupClasses={formElement.config.inputGroupClasses}
                                changed={event => this.inputChangedHandler(event, formElement.id)}
                            />
                        ))
                    }

                    {
                        error && <div className="server-msg">{error}</div>
                    }
                    {/* <ReCaptcha
                        apiKey={'6LcyQ-sUAAAAAGb5nyVY9CnZJiTYIARdrkWbe3EA'}
                        ref={this.captchaElement}
                        id="login-recaptcha"
                        customClass="customRecaptcha"
                        getRef={(ref) => {
                            this.captchaElement = ref;
                        }}
                        onChange={this.onCaptchaResolved}
                        resetId={(id) => {
                            this.captchaElementResetId = id;
                        }}
                    /> */}
                    <button className="button fill full big mt10 mb40" type="submit" onClick={() => this.validateLoginFromServer()}>
                       Submit
                    </button>
                </form>

                <div className="prev-link" onClick={openOTP}>
                    <i className="icon">
                        <svg width="16" height="7" viewBox="0 0 32 13">
                            <path fill="#E50079" fillRule="evenodd" d="M31.799 6.98l-5.83 5.82a.688.688 0 0 1-.972 0V.19a.688.688 0 0 1 .972 0l5.83 5.82a.684.684 0 0 1 0 .97zM18 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 18 9zm-8 0a2 2 0 1 1-.001-3.999A2 2 0 0 1 10 9zM2 9a2 2 0 1 1-.001-3.999A2 2 0 0 1 2 9z" />
                        </svg>
                    </i>
                    Back
                </div>
            </Fragment>
        )
    }
}

export default withRouter(VerifyOTP);
