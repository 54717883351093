import React, {Component} from 'react';
import FormValidator from "../../../../services/FormValidator";
import Input from "../../../Common/Input";
import {Link} from 'react-router-dom';
import {PATHS} from '../../../../constants'
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import {withRouter} from 'react-router-dom';
import Loader from "../../../Loader";
import {isAuthenticated} from "../../../../helper";
// import ReCaptcha from '../../../Common/GoogleCaptcha/googleCaptcha';
import { interceptedData } from '../../../../services/commonUtils';

class VerifyOTPComponent extends Component {
    state = {
        verifyOTPForm: {
            mobileInfo: {
                type: 'heading',
                title: "Mobile Number OTP",
                description: "A 6 digit OTP code has been sent  to your mobile number",
                value: ''
            },
            mobileOTP: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'otp'],
                labelConfig: {
                    label: 'Enter OTP',
                    classes: ['floating-label'],
                },
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Enter OTP',
                    maxLength: 6,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
                resendOtp: true
            },
            emailInfo: {
                type: 'heading',
                title: "Email ID OTP",
                description: "A 6 digit OTP code has been sent  to your email address",
                value: ''
            },
            emailOTP: {
                wrapperClasses: ['form-element'],
                inputGroupClasses: ['icon', 'otp'],
                labelConfig: {
                    label: 'Enter OTP',
                    classes: ['floating-label'],
                },
                elementType: 'input',
                elementConfig: {
                    type: 'password',
                    placeholder: 'Enter OTP',
                    maxLength: 6,
                    tabIndex: 0,
                },
                value: '',
                errorMessage: '',
                valid: true,
                touched: false,
                resendOtp: true
            },
        },
        firmName:'',
        error: '',
        isLoading: false,
    };

    captchaElement = React.createRef();

    // onCaptchaResolved = (captchaToken) => {
    //     (captchaToken && this.validateUserFromServer(captchaToken));
    // };

    // onValidateCaptcha = () => {
    //     window.grecaptcha && window.grecaptcha.reset();
    //     this.captchaElement.execute(this.captchaElementResetId); // this.captchaElementResetId
    // }

    componentDidMount() {
        const { history: { location : { state } }, history } = this.props;
        if(!isAuthenticated()) {
            if(state && state.mobileNumber && state.email && state.firmName) {
                const {mobileNumber, email, firmName} = state;
                const {verifyOTPForm} = this.state;
                const updateForm = {...verifyOTPForm};
                updateForm.mobileInfo.value = mobileNumber;
                updateForm.emailInfo.value = email;
                this.setState( {
                    verifyOTPForm: updateForm,
                    firmName
                })
            } else {
                history.push(PATHS.LOGIN);
            }
        } else {
            history.push(PATHS.HOME)
        }
    }

    inputOTPHandler = (inputValue, inputIdentifier) => {
        const {verifyOTPForm} = this.state;
        const updatedForm = {...verifyOTPForm};
        const updatedFormElement = {...updatedForm[inputIdentifier]};
        let value = inputValue;
        if (inputIdentifier === 'mobileOTP' || inputIdentifier === 'emailOTP') {
            value = value.replace(/[^0-9]/g, '');
        }
        updatedFormElement.value = value;
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.errorMessage = '';
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({verifyOTPForm: updatedForm, error: ''})
    };

    authenticateOTP = (e) => {
        e.preventDefault();
        const {verifyOTPForm} = this.state;
        const validateObj = {};
        const formKeys = Object.keys(verifyOTPForm);
        formKeys.forEach((key) => {
            validateObj[key] = verifyOTPForm[key].value;
        });
        const formStatus = FormValidator.validateOTPForm(validateObj);
        const {hasErrors, ...errorMessages} = formStatus;
        if (hasErrors) {
            const updatedForm = {...verifyOTPForm};
            formKeys.forEach((key) => {
                const inputKey = `${key}Message`;
                const errorMessage = errorMessages[inputKey];
                if (errorMessage && errorMessage !== '') {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = errorMessage;
                }
            });
            this.setState({verifyOTPForm: updatedForm, formErrorMessage: ''});
        }
        //  else {
        //     this.onValidateCaptcha();
        // }
    };

    validateUserFromServer = async (captchaToken) => {
        const {firmName, verifyOTPForm} = this.state;
        const validateObj = {};
        const formKeys = Object.keys(verifyOTPForm);
        formKeys.forEach((key) => {
            validateObj[key] = verifyOTPForm[key].value;
        });
        const payload = {
            firmName,
            mobileNumber: validateObj.mobileInfo,
            mobileNumberCode: validateObj.mobileOTP,
            email: validateObj.emailInfo,
            emailCode: validateObj.emailOTP,
            registrationFormPrivacy: true,
            // captchaToken
        };
        this.setState({isLoading: true});
        try {
            const response = await commonService.registerUserApi(payload);
            const responseData = interceptedData(response.data);
            // this.onValidateResetCaptcha()

            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false});
                const loginToken = responseData.data.token;
                localStorage.setItem('token', `Bearer ${loginToken}`);
                localStorage.setItem('role', JSON.stringify(responseData.data.role));
                localStorage.setItem('name', responseData.data.name);
                localStorage.setItem('roleCategory', JSON.stringify(responseData.data.roleCategory));

                const { history } = this.props;
                history.push(PATHS.HOME);
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch(e) {
            if (e.response && e.response.status === 429) {
                this.setState({ error: "You have exceeded the number of attempts. Please try after sometime.", isLoading: false });
            } else {
                console.log(e);
                this.setState({ error: GlobalConfig.serverError, isLoading: false });
            }
        }
    };

    reGenerateOtp = async (key) => {
        const { history: { location : { state : {mobileNumber, email, firmName} } } } = this.props;
        this.setState({isLoading: true, error: ''});
        let payload = {service: "REGISTER", registrationFormPrivacy: true};
        if(key === 'emailOTP') {
            payload.email = email;
            payload.name = firmName
        } else payload.mobileNumber = mobileNumber;


        try {
            const response = await commonService.generateOtp(payload);
            const responseData = response.data;
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({isLoading: false})
            } else {
                this.setState({error: responseData.message, isLoading: false})
            }
        } catch (e) {
            if (e.response && e.response.status === 429) {
                this.setState({ error: "You have exceeded the number of attempts. Please try after sometime.", isLoading: false });
            } else {
                console.log(e);
                this.setState({ error: GlobalConfig.serverError, isLoading: false });
            }
        }
    };

    render() {
        const {verifyOTPForm, error ,isLoading} = this.state;
        const {authenticateOTP} = this;
        let formElementsArray = [];
        const formKeys = Object.keys(verifyOTPForm);
        formKeys.forEach((key) => {
            formElementsArray.push({
                id: key,
                config: verifyOTPForm[key],
            });
        });
        return (
            <div className="auth-container">
                {
                    isLoading && <Loader/>
                }
                <div className="outer-layer">
                    <div className="inner">
                        <h2 className="heading pb10">
                            Verify
                        </h2>

                        <form onSubmit={authenticateOTP}>
                            {
                                formElementsArray && formElementsArray.map((formElement) => (
                                    formElement.config.type === 'heading' ?
                                        <div className="otp-screen">
                                            <hr className="no-space extra-space" />
                                            <div className="main-title pb10">
                                                {formElement.config.title}
                                            </div>
                                            <div className="description pb10">
                                                {formElement.config.description}
                                                <span className="value pl5">
                                                   {formElement.config.value}
                                                </span>
                                            </div>
                                        </div> :
                                        <Input
                                            key={formElement.id}
                                            labelConfig={formElement.config.labelConfig}
                                            elementType={formElement.config.elementType}
                                            elementConfig={formElement.config.elementConfig}
                                            value={formElement.config.value}
                                            valid={formElement.config.valid}
                                            errorMessage={formElement.config.errorMessage}
                                            touched={formElement.config.touched}
                                            resendOtp={formElement.config.resendOtp}
                                            reGenerateOtp={()=>this.reGenerateOtp(formElement.id)}
                                            wrapperClasses={formElement.config.wrapperClasses}
                                            inputGroupClasses={formElement.config.inputGroupClasses}
                                            changed={event => this.inputOTPHandler(event, formElement.id)}
                                        />
                                ))
                            }

                            {
                                error && <div className="server-msg">{error}</div>
                            }
                            {/* <ReCaptcha
                                apiKey={'6LcyQ-sUAAAAAGb5nyVY9CnZJiTYIARdrkWbe3EA'}
                                ref={this.captchaElement}
                                id="login-recaptcha"
                                customClass="customRecaptcha"
                                getRef={(ref) => {
                                    this.captchaElement = ref;
                                }}
                                onChange={this.onCaptchaResolved}
                                resetId={(id) => {
                                    this.captchaElementResetId = id;
                                }}
                            /> */}
                            <button className="button fill full big mt10 mb40" type="submit" onClick={() => this.validateUserFromServer()}>
                                VERIFY NOW
                            </button>
                        </form>

                        <div className="notes">
                            <Link to={{pathname: PATHS.LOGIN, activeTab: 1}} className="title">
                                Edit Mobile Number or Email ID
                            </Link>
                        </div>
                    </div>
                    <div className="layer-strip"/>
                </div>
            </div>
        )
    }
}

export default withRouter(VerifyOTPComponent);
