import React, { Component, Fragment } from 'react';
import Loader from "../../../Loader";
import { commonService } from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Error from "../../../Common/Error";
import { INPUT_MAX_LENGTH } from "../../../../constants";
import { PARTNER_CREATION_FIELDS } from "./constant";
import { MODALS } from "../../../../storeManager/modal/constants";
import FormValidator from "../../../../services/FormValidator";
import { openModal as openModalHandler } from "../../../../storeManager/modal/actions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { buttonApiObj } from "../../helper";
import './style.scss';
import PageFrom from './PageFrom';
import { getWeekWithOptions } from 'date-fns/fp';
import { format} from 'date-fns';
import { interceptedData } from '../../../../services/commonUtils';

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
});


class PartnerCreationModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            selectForm: PARTNER_CREATION_FIELDS(),
            activeStep: 1,
            intentFormData: this.props?.modalParameters?.responseObject,
            applicationId: this.props?.modalParameters?.responseObject?.id,
            buttonInfo: this.props?.modalParameters?.buttonInfo,
            formErrorMessage: ''
        };
    }

    componentDidMount() {
        this.getInitialData()
    }
    inputChangedHandler = (inputValue, inputIdentifier) => {
        const { selectForm } = this.state;
        const updatedForm = { ...selectForm };
        const updatedFormElement = { ...updatedForm[inputIdentifier] };
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.value = inputValue;
        updatedFormElement.errorMessage = "";
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({ selectForm: updatedForm });
    };

    validateForm = () => {
        const { selectForm } = this.state;
        const validateObj = {};
        const formKeys = Object.keys(selectForm);
        formKeys.forEach(key => {
            validateObj[key] = selectForm[key].value;
        });
        const formStatus = FormValidator.validatePartnerCreationForm(selectForm);
        const { hasErrors, formInputStatus } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...selectForm };
            formInputStatus.forEach(field => {
                // const inputKey = `${key}Message`;
                // const errorMessage = errorMessages[inputKey];
                const { key, page, error, message } = field
                if (error && message !== "") {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = message;
                }
                else {
                    updatedForm[key].valid = true;
                    updatedForm[key].errorMessage = '';
                }
            });
            const page = formInputStatus.find(x => x.error).page;
            this.setState({ selectForm: updatedForm, formErrorMessage: "", activeStep: page });
            return false;
        } else {
            return true;
        }
    };
    getOptions = (optionsStr) => {
        const optionArr = JSON.parse(optionsStr)
        const options = [];
        optionArr.forEach((e, ind) => {
            options.push({
                label: e,
                value: e,
                key: ind
            })
        })
        return options;
    }
    getInitialData = () => {
        const { applicationId, selectForm, intentFormData } = this.state;
        const updatedForm = { ...selectForm }
        this.setState({ isLoading: true })
        Promise.all([
            commonService.createPartnerDropdownList(),
            commonService.getPartnerFormInfo(applicationId)
        ]).then((res) => {
            const { data: formDropdownData } = res[0];
            const { data: formDraftData } = res[1];
            if (formDropdownData.status) {
                formDropdownData.data.forEach(obj => {
                    if (obj.name === 'region') {
                        updatedForm.region.elementConfig.options = [...updatedForm.region.elementConfig.options, ...this.getOptions(obj.value)]
                    }
                    if (obj.name === 'distributor_channel_type') {
                        updatedForm.distributorChannelType.elementConfig.options = [...updatedForm.distributorChannelType.elementConfig.options, ...this.getOptions(obj.value)]
                    }
                    if (obj.name === 'accounting_clerk') {
                        updatedForm.accountingClerk.elementConfig.options = [...updatedForm.accountingClerk.elementConfig.options, ...this.getOptions(obj.value)]
                    }
                    if (obj.name === 'customer_group_1') {
                        updatedForm.customerGroup1.elementConfig.options = [...updatedForm.customerGroup1.elementConfig.options, ...this.getOptions(obj.value)]
                    }
                    if (obj.name === 'indicator_for_withholding_tax_type') {
                        const options = [...updatedForm.indicatorForWithholdingTaxType1.elementConfig.options, ...this.getOptions(obj.value)]
                        updatedForm.indicatorForWithholdingTaxType1.elementConfig.options = options
                        updatedForm.withholdingTaxCode1.elementConfig.options = options
                    }
                })
            }
            if (formDraftData.status) {
                for (const el in formDraftData.data) {
                    if (el !== 'applicationId')
                        updatedForm[el].value = formDraftData.data[el]
                }
            }
            else {
                updatedForm.name.value = intentFormData?.firm?.name;
                updatedForm.streetHouseNumber.value = intentFormData?.firm?.address?.line;
                updatedForm.postalCode.value = intentFormData?.firm?.address?.pinCode?.code;
                updatedForm.mobilePhone.value = intentFormData?.firm?.phoneNumber;
                updatedForm.email.value = intentFormData?.firm?.emailId;
                updatedForm.taxNumber.value = intentFormData?.firm?.panNo;
                updatedForm.gst.value = intentFormData?.firm?.gstin;
                updatedForm.withholdingTaxIdentificationNumber1.value = intentFormData?.firm?.panNo;
                updatedForm.withholdingTaxIdentificationNumber2.value = intentFormData?.firm?.panNo;
            }
            this.setState({ isLoading: false, selectForm: updatedForm })

        }).catch(err => {
            this.setState({ isLoading: false })
        })

    }
    handleSaveAsDraft = async (e) => {
        const { closePopup } = this.props;

        e.preventDefault();
        const { selectForm, applicationId } = this.state;
        const payload = {};
        for (const key in selectForm) {
            payload[key] = selectForm[key].value;
        }
        payload.applicationId = `${applicationId}`;
        try {
            this.setState({ isLoading: true })
            const response = await commonService.draftPartnerForm(payload);
            const responseData = response?.data;
            if (responseData?.status === GlobalConfig.MESSAGES_TYPES.FALSE) {

                this.setState({
                    formErrorMessage: responseData.message,
                    isLoading: false
                });
            } else {
                this.setState({
                    formErrorMessage: '',
                    isLoading: false
                });
                closePopup();
            }
        } catch {
            this.setState({
                formErrorMessage: GlobalConfig.serverError,
                isLoading: false
            });
        }

    }
    partnerChangeStatus = async () => {
        const { intentFormData, buttonInfo } = this.state;
        const payload = {
            partner: intentFormData,
            actionId: buttonInfo.id
        }
        try {
            this.setState({ isLoading: true })
            const response = await commonService.partnerChangeState(payload);
            const responseData = interceptedData(response.data);
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                this.setState({
                    isLoading: false
                });
            } else {
                this.setState({
                    // formErrorMessage: responseData.message,
                    isLoading: false
                });
            }
        } catch {
            this.setState({
                // formErrorMessage: GlobalConfig.serverError,
                isLoading: false
            });
        }

    }
    formSubmit = async (e) => {
        e.preventDefault();
        const { openModal, modalParameters: { resetPage } } = this.props;
        const { selectForm, applicationId, buttonInfo } = this.state;

        const payload = {};
        for (const key in selectForm) {
            if (selectForm[key].elementConfig.type === 'date') {
                payload[key] = selectForm[key].value.split('-').reverse().join('.');
            }
            else{
                payload[key] = selectForm[key].value;
            }
        }
        const validateResponse = this.validateForm();

        if (validateResponse) {
            const { closePopup } = this.props;
            payload.applicationId = `${applicationId}`
            try {
                this.setState({ isLoading: true })
                const response = await commonService.createPartnerForm(payload);
                // const responseData = response.data;
                if (response?.data?.status === GlobalConfig.MESSAGES_TYPES.FALSE) {

                    this.setState({
                        formErrorMessage: response?.data?.message,
                        isLoading: false
                    });

                } else {
                    this.setState({
                        isLoading: false,
                        formErrorMessage: ''
                    });
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    const date = new Date();
                    const DD = ("0" + date.getDate()).slice(-2)
                    const MM = ("0" + (date.getMonth() + 1)).slice(-2)
                    const YY = date.getFullYear().toString().substr(-2)
                    const HH = date.getHours()
                    const SS = date.getSeconds()
                    link.setAttribute('download', `${applicationId}${DD}${MM}${YY}${HH}${SS}.csv`);
                    document.body.appendChild(link);
                    link.click();
                    this.partnerChangeStatus();
                    closePopup();
                    openModal(MODALS.CONFIRMATION_POPUP, {
                        message: buttonInfo.message,
                        hideCloseButton: true,
                        onCloseCustomFunction: () => resetPage(),
                        isCustomCloseFunction: true
                    });
                }
            } catch {
                this.setState({
                    formErrorMessage: GlobalConfig.serverError,
                    isLoading: false
                });
            }
        } else {
            this.setState({ isLoading: false, formErrorMessage: "" });
        }
    }
    handlePage = (nav) => {
        const { activeStep } = this.state;
        if (nav === 'next')
            this.setState({ activeStep: activeStep + 1 })
        if (nav === 'prev')
            this.setState({ activeStep: activeStep - 1 })
    }

    render() {
        const { isLoading, selectForm, activeStep, formErrorMessage } = this.state;
        const elementArray = Object.entries(selectForm)
        return (
            <Fragment>
                {
                    isLoading && <Loader />
                }
                <div className="custom-modal-header pb25">
                    <div className="title">
                        Partner Code Creation
                    </div>
                </div>
                <div className='stepper-wrap'>
                    <div className={`step ${activeStep >= 1 ? 'active' : ''}`}>
                        Page 1
                    </div>
                    <div className={`step ${activeStep >= 2 ? 'active' : ''}`}>
                        Page 2
                    </div>
                    <div className={`step ${activeStep >= 3 ? 'active' : ''}`}>
                        Page 3
                    </div>
                    <div className={`step ${activeStep >= 4 ? 'active' : ''}`}>
                        Page 4
                    </div>

                </div>

                <div className="">
                    <form
                    >
                        {
                            selectForm && activeStep === 1 &&
                            <PageFrom
                                formElement={elementArray.filter((el) => el[1].page === 1)}
                                inputChangedHandler={this.inputChangedHandler}
                            />
                        }
                        {
                            selectForm && activeStep === 2 &&
                            <PageFrom
                                formElement={elementArray.filter((el) => el[1].page === 2)}
                                inputChangedHandler={this.inputChangedHandler}
                            />
                        }
                        {
                            selectForm && activeStep === 3 &&
                            <PageFrom
                                formElement={elementArray.filter((el) => el[1].page === 3)}
                                inputChangedHandler={this.inputChangedHandler}
                            />
                        }
                        {
                            selectForm && activeStep === 4 &&
                            <PageFrom
                                formElement={elementArray.filter((el) => el[1].page === 4)}
                                inputChangedHandler={this.inputChangedHandler}
                            />
                        }

                    </form>
                </div>
                {formErrorMessage && <Error error={formErrorMessage} />}

                <div className="bottom-action-btn">
                    <button className="button full big mb10 fill" type="button" onClick={(e) => this.handleSaveAsDraft(e)}>
                        Save as Draft
                    </button>
                    <button className="button full big mb10 fill" onClick={(e) => this.handlePage('prev')} type="button" disabled={activeStep === 1}>
                        Previous
                    </button>
                    {activeStep === 4 ?
                        <button className="button full big mb10 action" onClick={(e) => this.formSubmit(e)} type="button">
                            Submit & Download CSV
                        </button>
                        :
                        <button className="button full big mb10 action" onClick={(e) => this.handlePage('next')} type="button">
                            Next
                        </button>
                    }

                </div>
            </Fragment>
        );
    }
}


export default connect(null, mapDispatchToProps)(PartnerCreationModal);
