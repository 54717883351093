import React, {Component} from 'react';
import DetailLeftSection from "./LeftSection";
import DetailRightSection from "./RightSection";
import './listing-detail.scss';
import {withRouter} from 'react-router-dom';
import {commonService} from "../../storeManager/common/services";
import GlobalConfig from "../../globalConfig/globalConfig";
import {DefaultState, Mapper } from "./LeftSection/helper";
import Loader from "../Loader";
import { PATHS } from "../../constants";
import './RightSection/content.scss';
import { interceptedData } from '../../services/commonUtils';

class ListingDetail extends Component {
    state = {
        detailResponse: DefaultState(),
        businessAssociations: [],
        activeSliderTab: 0,
        activeTab: 0,
        isLoading: false,
        responseObject: {},
        store: {}
    };

    tabChanged = (index) => {
        this.setState({activeSliderTab: index});
    };
    tabToggled = (index) => {
        this.setState({activeTab: index});
    };

    componentDidMount() {
        this.getInitialData();
    }

    getInitialData = () => {
        const {history} = this.props;
        const requestId = history.location && history.location.state && history.location.state.requestId;
        if(requestId){
            this.getPartnerDetails(requestId);
        }else{
            history.push(PATHS.DASHBOARD);
        }
    }

    resetPage = () => {
        // this.getInitialData();
        window.location.reload();
    }

    getPartnerDetails = async (id) => {
        const {detailResponse} = this.state;
        this.setState({isLoading: true});
        try {
            const response = await commonService.getPartnerDetails(id);
            const responseData = interceptedData(response.data);
            if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                const responseObject = responseData.data;
                let updateBusinessAssociations = [...responseObject.businessDetail.businessAssociations];
                updateBusinessAssociations && updateBusinessAssociations.map(item => {
                    item.digitalService = item.digitalService.name || '';
                    return item
                });
                let updatedForm = Mapper(
                    responseObject,
                    detailResponse
                );
                this.setState({
                    isLoading: false,
                    businessAssociations: updateBusinessAssociations,
                    detailResponse: {...updatedForm},
                    responseObject: responseObject,
                    store: responseObject
                })

            } else {
                this.setState({error: responseData.message, isLoading: false});
            }
        } catch {
            this.setState({error: GlobalConfig.serverError, isLoading: false});
        }
    };

    render() {
        const {
            businessAssociations, isLoading, activeSliderTab, responseObject, store, activeTab,
        detailResponse:{
            requestId,
            firmName,
            firmAddress,
            email,
            mobileNumber,
            firmDetailList,
            businessDetailList,
            bankDetails,
            totalBanks,
            partnerDetails,
            partnerCounts,
            existingPartnerShipsDetails,
            uploadedDocuments,
            warehouseDetails,
            sliderSettings,
            activityList,
            appliedPartnershipDetails,
            pendingDays,
            createdDate,
            allActions,
            ticketAssignedTo,
            ticketAssignedToRole,
            pendingDocuments,
            totalDocuments
        }
        } = this.state;
        const {tabChanged, tabToggled} = this;
        return (
            <section className="list-detail-wrapper">
                {
                    isLoading && <Loader/>
                }
                <div className="row no-gutters">
                    <div className="col-lg-9 left-section-wrapper">
                        <DetailLeftSection
                            activeSliderTab={activeSliderTab}
                            tabChanged={tabChanged}
                            requestId={requestId}
                            firmName={firmName}
                            firmAddress={firmAddress}
                            email={email}
                            mobileNumber={mobileNumber}
                            firmDetailList={firmDetailList}
                            businessDetailList={businessDetailList}
                            businessAssociations={businessAssociations}
                            bankDetails={bankDetails}
                            totalBanks={totalBanks}
                            partnerDetails={partnerDetails}
                            partnerCounts={partnerCounts}
                            existingPartnerShipsDetails={existingPartnerShipsDetails}
                            uploadedDocuments={uploadedDocuments}
                            warehouseDetails={warehouseDetails}
                            sliderSettings={sliderSettings}
                            pendingDays={pendingDays}
                            createdDate={createdDate}
                            ticketAssignedTo={ticketAssignedTo}
                            ticketAssignedToRole={ticketAssignedToRole}
                            responseObject={responseObject}
                            store={store}
                            activeTab={activeTab}
                            tabToggled={tabToggled}
                        />
                    </div>
                    <div className="col-lg-3 right-section-wrapper">
                        <DetailRightSection
                            responseObject={responseObject}
                            allActions={allActions}
                            activityList={activityList}
                            resetPage={this.resetPage}
                            appliedPartnershipDetails={appliedPartnershipDetails}
                            totalDocuments={totalDocuments}
                            pendingDocuments={pendingDocuments}
                        />
                    </div>
                </div>
            </section>
        )
    }
}

export default withRouter(ListingDetail);
