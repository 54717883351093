import React, { Component } from 'react';
import { TEXT, NOTIFICATION_TEMPLATE_HEADING } from '../constant';
import InputBox from '../InputBox';
import { commonService } from "../../../storeManager/common/services";
import { ownersList, notificationList } from '../../../storeManager/common/repository';
import RadioButton from '../RadioButton';
import GlobalConfig from "../../../globalConfig/globalConfig";
import { openModal as openModalHandler } from "../../../storeManager/modal/actions";
// import Modal from "../../Common/Modal";
import { connect } from 'react-redux';
import { MODALS } from "../../../storeManager/modal/constants";
import { bindActionCreators } from 'redux';
import { AxiosService } from '../../../services/apiService';
import Table from '../Table';
import { scrollToTop } from '../../../helper';
import Loader from '../../Loader';
// import InputsValidator from '../../../services/FormValidator/InputsValidator';
import FormValidator from '../../../services/FormValidator';
import { interceptedData } from '../../../services/commonUtils';

class NotificationTemplate extends Component {
    defaultForm = () =>({
        name: "",
        role: {},
        message: "",
        id: null,
      });

    state = {
        formData: this.defaultForm(),
        ownersList: [],
        isEdit: false,
        isLoading: true,
        notificationList: [],
        errorObj : {
            nameError: "",
            messageError: "",
            roleError: ""
        }
    }

    componentDidMount = () => {
        this.loadHandler();
    }

    loadHandler = async () => {
        const ownerApi = new AxiosService(ownersList);
        const notificationAPi = new AxiosService(notificationList);
        Promise.all([
            ownerApi.doAjax(),
            notificationAPi.doAjax()
        ])
            .then(res => {
                const [
                    { data: { data: ownersList } },
                    { data: { data: notificationList} }
                ] = res;
                this.setState({
                    ownersList,
                    notificationList:interceptedData(notificationList),
                    isLoading: false
                  })
            })
            .catch(err => {
                this.setState({isLoading: false});
                console.log('Error in api call', err);
            })
    };

    handleChange = (name, value) => {
        const updatedFormData = { ...this.state.formData }
        updatedFormData[name] = value;
        this.setState({
        formData: updatedFormData,
        })
    }

    handleClick = async (id) => {
        const response = await commonService.getNotificationTemplate(id);
        if(response){
            const {data: {data}} = response;
            this.setState({
                formData:data,
                isEdit: true
            }, () => scrollToTop())
        }
    }

    handleSubmit = async (event) => {
        event.preventDefault();
        const { formData : {name, id, message, role} } = this.state;
        const payload = {
            id: null||id,
            name: name,
            message:message,
            role: role,
        }
        if (this.validateForm(payload)) {
            try {
                this.setState({
                    isLoading: true
                });
                const response = await commonService.createNotificationTemplate(payload);
                const responseData = response.data;
                const { openModal } = this.props;
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    this.setState({
                        isLoading: false
                    });
                    openModal(MODALS.CONFIRMATION_POPUP, {
                        message: responseData.message
                    });
                    this.resetForm();
                } else {
                    this.setState({
                        error: responseData.message,
                        isLoading: false
                    })
                }
            }
            catch {
                this.setState({
                    error: GlobalConfig.serverError,
                    isLoading: false
                })
            }
        }
    };

    validateForm = (payload) => {
        let { errorObj } = this.state;
        const formStatus = FormValidator.validateAdminNotificationTemplet(payload);
        if (formStatus.hasErrors) {
            errorObj = formStatus;
            this.setState({
                errorObj    
            })
        }
        else {
            return true;
        }
    }

    resetForm = () => {
        this.setState({
            formData: this.defaultForm(),
            isEdit: false,
            errorObj: {
                nameError: "",
                messageError: "",
                roleError :""
            }
        }, () => this.loadHandler())
    };

    handleRadioButton = (name, value, id) => {
        this.handleChange(name, {id});
    };

    render() {
        const {formData: { name, message, role} ,isEdit, isLoading, ownersList, notificationList ,errorObj :{nameError, messageError, roleError} } = this.state;
        const escownerList = ownersList && ownersList.map((owner, index) => {
            return <RadioButton
                key={index}
                name="role"
                id={owner.id}
                value={owner.name}
                onChange={this.handleRadioButton}
                isChecked={role.id === owner.id}
            />
          });
        return (
            <div className="business-workflow">
                {isLoading && <Loader />}
                <h2 className="heading mb-15">{TEXT.NOTIFICATION_TEMPLATE_HEADING}</h2>
                <div className="sub-heading">{TEXT.NOTIFICATION_TEMPLATE_SUBTITLE}</div>
                <div className="content-form no-gutters">
                    <div className="col-lg-8 no-gutters">
                    <h3 className="content-title">{`${isEdit ? 'UPDATE' : 'CREATE'} Notification Template`}</h3>
                        <div className="row">
                            <div className="form-name col-lg-6">
                                <InputBox
                                    labelName='Name'
                                    name='name'
                                    className='form-control'
                                    type="text"
                                    value={name}
                                    onChange={this.handleChange}
                                    errorsMessage = {nameError || ''}
                                />
                            </div>
                            <div className="form-name col-lg-6">
                                <InputBox
                                    labelName='Message'
                                    name='message'
                                    className='form-control'
                                    type="text"
                                    value={message}
                                    onChange={this.handleChange}
                                    errorsMessage = {messageError || ''}
                                />
                            </div>
                        </div>
                        <div className="row">
                        <div className="form-name col-lg-6">
                        <label>Role</label>
                        <div className="owner-bg">
                            {escownerList}
                        </div>
                        <div className="error-message">{roleError || ''}</div>
                        </div>
                        </div>
                        <div>
                            <button className="button fill big w-210 mt10 mb30 mr20" onClick={this.handleSubmit}>{`${isEdit ? 'UPDATE' : 'CREATE'}`}</button>
                        </div>
                    </div>
                </div>
                <div className="table-container mt45">
                    <h3>Notifications Template</h3>
                    <div className="table-data-list">
                    <Table showHead headings={NOTIFICATION_TEMPLATE_HEADING} rows={notificationList} handleClick={this.handleClick} />
                    </div>
                </div>
                {/*<Modal />*/}
            </div>
        )
    }
}

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
});

export default (connect(null, mapDispatchToProps)(NotificationTemplate))
