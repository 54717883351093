import React, { Component, Fragment } from "react";
import { withRouter } from "react-router-dom";

import MobileLeftPanel from "./MobileLeftPanel";
import LeftPanel from "./LeftPanel";
import RightPanel from "./RightPanel";
import "./dashboard.scss";
import GlobalConfig from "../../globalConfig/globalConfig";
import { commonService } from "../../storeManager/common/services";
import Loader from "../Loader";
import { isAuthenticated } from "../../helper";
import { PATHS } from "../../constants";
import { interceptedData } from "../../services/commonUtils";

class Dashboard extends Component {
  state = {
    error: "",
    taskList: [],
    filterListByRole: [],
    filterListByStatus: [],
    selectedRoleObj: {},
    selectedStatusObj: {},
    totalCount: 0,
    itemsPerPage: 10,
    showResultsArray: [10, 15, 20, 25],
    selectedPage: 1,
    headerValues: {
      sortBy: ""
    },
    searchText:'',
    isFilterDataLoading: true,
    isMainDataLoading: true,
    activeTab: 0,
    lobList:[]
  };

  componentDidMount() {
    if (isAuthenticated()) {
        this.getFilterData();
        this.getLob();
    } else {
      const { history } = this.props;
      history.push(PATHS.LOGIN);
    }
  }

  getFilterData = async () => {
    const payload = {};
    try {
      const response = await commonService.dashBoardFilterList(payload);
      const responseData = response.data;
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let filterListByRole =
          (responseData &&
            responseData.data &&
            responseData.data.roleFilterData) ||
          [];
        let filterListByStatus =
          (responseData && responseData.data && responseData.data.statusData) ||
          [];

        const loggedInUserRole = JSON.parse(localStorage.getItem("role"));
        const roleId = (loggedInUserRole && loggedInUserRole.id) || "";
        let selectedRoleObj = this.getSelectedDetails(filterListByRole, roleId);
        this.setState({
          isFilterDataLoading: false,
          filterListByRole: filterListByRole,
          filterListByStatus: filterListByStatus,

          selectedRoleObj: { ...selectedRoleObj }
        });
      } else {
        this.setState({ error: responseData.message, isFilterDataLoading: false, });
      }
    } catch {
      this.setState({ error: GlobalConfig.serverError, isFilterDataLoading: false, });
    }
  };

  getDashBoardWithLob = async (id,lobList) =>{
    this.setState({ isMainDataLoading: true, });
    const {
      selectedPage,
      itemsPerPage,
      selectedRoleObj,
      selectedStatusObj,
      headerValues,
      searchText,
      activeTab
    } = this.state;
    let offset = selectedPage ? selectedPage - 1 : 0;
    let roleId = (selectedRoleObj && selectedRoleObj.id) || null;
    let status = (selectedStatusObj && selectedStatusObj.status) || "";
    if (!roleId) {
      const loggedInUserRole = JSON.parse(localStorage.getItem("role"));
      roleId = (loggedInUserRole && loggedInUserRole.id) || "";
    }
    const payload = {
      offset: offset,
      searchText: searchText,
      sortType: headerValues.sortBy,
      createdDate: {
        from: "",
        to: ""
      },
      role: roleId,
      status: status,
      limits: itemsPerPage,
      lobId: id 
    };
    
    try{

      const response = await commonService.dashBoardList(payload);
      const responseData = interceptedData(response.data);
      if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
        let taskList =
          (responseData && responseData.data && responseData.data.listDTO) ||
          [];
        const totalCount =
          (responseData && responseData.data && responseData.data.total) || 0;
        lobList[activeTab].total = responseData.data.total;
        Object.keys(responseData.data.otherLineOfBusinessCount).forEach(key=>{
          let index = lobList.findIndex(lob=> lob.id===parseInt(key))
          lobList[index].total = responseData.data.otherLineOfBusinessCount[key];
        })
        this.setState({
          isMainDataLoading: false,
          taskList: taskList,
          totalCount: totalCount,
          lobList: lobList
        });
      } else {
        this.setState({ error: responseData.message, isMainDataLoading: false,});
      }
    }catch(error){
      this.setState({ error: GlobalConfig.serverError, isMainDataLoading: false, });
    }
  }

getLob = async () => {
  try{
    let lobList = await commonService.fetchBusinessControllerData()
    if(lobList && lobList?.data?.data.length){
      const { id } = lobList.data.data[0];
      
      this.getDashBoardWithLob(id,lobList.data.data);
    }
  }catch{
    this.setState({ error: GlobalConfig.serverError, isFilterDataLoading: false, });
  }
};

  itemsPerPageChanged = value => {
    this.setState({ itemsPerPage: value, selectedPage: 1 }, () => {
      const { id } = this.state.lobList[this.state.activeTab]
      this.getDashBoardWithLob(id,this.state.lobList);
    });
  };

  handlePageChange = value => {
    this.setState({ selectedPage: value }, () => {
      const { id } = this.state.lobList[this.state.activeTab]
      this.getDashBoardWithLob(id,this.state.lobList);
    });
  };

  getSelectedDetails = (filterListByRole, roleId) => {
    let selectedRoleArray =
      filterListByRole &&
      filterListByRole.length &&
      filterListByRole.filter(item => item.id === roleId);
    let selectedRoleObj = (selectedRoleArray &&
      selectedRoleArray.length &&
      selectedRoleArray[0]) || { label: "", count: "", id: "" };
    return selectedRoleObj;
  };

  redirectOnClick = requestId => {
    this.props.history.push(PATHS.DETAIL, {
      requestId: requestId
    });
  };

  handleFilterRoleChanged = selectedRoleObj => {
    this.setState(
      {
        selectedRoleObj: { ...selectedRoleObj },
        selectedPage: 1,
        itemsPerPage: 10,
        selectedStatusObj:{},
      },
      () => {
        const { id } = this.state.lobList[this.state.activeTab]
        this.getDashBoardWithLob(id,this.state.lobList);
      }
    );
  };

  handleFilterStatusChanged = selectedStatusObj => {
    this.setState({ selectedStatusObj: { ...selectedStatusObj },selectedRoleObj:{} }, ()=>{
      const { id } = this.state.lobList[this.state.activeTab]
      this.getDashBoardWithLob(id,this.state.lobList);
    });
  };

  onChangeSearchHandler = inputValue => {
    this.setState({
      searchText: inputValue,
      selectedPage: 1
    },()=>{
      const { id } = this.state.lobList[this.state.activeTab]
        this.getDashBoardWithLob(id,this.state.lobList);
    })
  };

  headerOnChange = (key, value) => {
    const { headerValues } = this.state;
    const updatedHeaderValues = { ...headerValues };
    updatedHeaderValues[key] = value;
    this.setState(
      {
        headerValues: updatedHeaderValues,
        selectedPage:1
      },
      () => {
        const { id } = this.state.lobList[this.state.activeTab]
        this.getDashBoardWithLob(id,this.state.lobList);
      }
    );
  };

  tabChangeHandler = (index) => {
    this.setState({
      activeTab: index,
      itemsPerPage: 10,
      selectedPage: 1,
      headerValues: {
        sortBy: ""
      },
      // searchText:'',
    },()=>{
      const { id } = this.state.lobList[index]
      this.getDashBoardWithLob(id,this.state.lobList)
    })
  }

  render() {
    const {
      taskList,
      filterListByRole,
      filterListByStatus,
      selectedRoleObj,
      showResultsArray,
      itemsPerPage,
      selectedPage,
      selectedStatusObj,
      headerValues,
      totalCount,
      isMainDataLoading,
      isFilterDataLoading,
      lobList,
      activeTab
    } = this.state;
    const {windowWidth} = this.props;
    return (
      <Fragment>
        {(isFilterDataLoading || isMainDataLoading) && <Loader />}
        <div className="row no-gutters">
          <div className="col-lg-2">
            {
                windowWidth < 992 ? (
                    <MobileLeftPanel
                      filterListByStatus={filterListByStatus}
                      tasksByRole={filterListByRole}
                      selectedRoleObj={selectedRoleObj}
                      filterRoleOnChange={this.handleFilterRoleChanged}
                      selectedStatusObj={selectedStatusObj}
                      filterStatusOnChange={this.handleFilterStatusChanged}
                      windowWidth={windowWidth}
                    />
                ) : (
                    <LeftPanel
                      filterListByStatus={filterListByStatus}
                      tasksByRole={filterListByRole}
                      selectedRoleObj={selectedRoleObj}
                      filterRoleOnChange={this.handleFilterRoleChanged}
                      selectedStatusObj={selectedStatusObj}
                      filterStatusOnChange={this.handleFilterStatusChanged}
                    />
                )
            }

          </div>
          <div className="col-lg-10 right-panel-wrapper">
            <RightPanel
              taskList={taskList}
              redirectOnClick={this.redirectOnClick}
              selectedRoleObj={selectedRoleObj}
              showResultsArray={showResultsArray}
              itemsPerPage={itemsPerPage}
              itemsPerPageChanged={this.itemsPerPageChanged}
              handlePageChange={this.handlePageChange}
              selectedPage={selectedPage}
              onChangeSearchHandler={this.onChangeSearchHandler}
              headerValues={headerValues}
              headerOnChange={this.headerOnChange}
              totalCount={totalCount}
              lobList={lobList}
              activeTab={activeTab}
              tabChangeHandler={this.tabChangeHandler}
            />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default withRouter(Dashboard);
