import React, {Component, Fragment} from 'react';
import Loader from "../../../Loader";
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Input from "../../../Common/Input";
import {INPUT_MAX_LENGTH} from "../../../../constants";
import {MODALS} from "../../../../storeManager/modal/constants";
import FormValidator from "../../../../services/FormValidator";
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {buttonApiObj} from "../../helper";
import { interceptedData } from '../../../../services/commonUtils';

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
});


class ApplicationCodeViewModal extends Component {
  state = {
    isLoading: false,
    selectForm: {
      partnerCode: {
        wrapperClasses: ["form-element w-50 partner-code"],
        labelConfig: {
          label: "Applicant Code",
          classes: ["label-title"],
        },
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Applicant Code",
          maxLength: INPUT_MAX_LENGTH.LENGTH_FIVE,
          tabIndex: 0,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
      },
      virtualCode: {
        wrapperClasses: ["form-element w-50 virtual-code"],
        labelConfig: {
          label: "Virtual Code",
          classes: ["label-title"],
        },
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Virtual Code",
          maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTEEN,
          tabIndex: 0,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
      },
      tataSkyBankAccountNumber: {
        wrapperClasses: ["form-element w-100"],
        labelConfig: {
          label: "Tata Play Bank Account Number",
          classes: ["label-title"],
        },
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Tata Play Bank Account Number",
          maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY,
          tabIndex: 0,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
      },
      tataSkyBankName: {
        wrapperClasses: ["form-element w-100"],
        labelConfig: {
          label: "Tata Play Bank Account Name",
          classes: ["label-title"],
        },
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Tata Play Bank Name",
          maxLength: INPUT_MAX_LENGTH.LENGTH_FIFTY,
          tabIndex: 0,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
      },
      tataSkyBankIfscCode: {
        wrapperClasses: ["form-element w-100"],
        labelConfig: {
          label: "Tata Play Bank IFSC Code",
          classes: ["label-title"],
        },
        elementType: "input",
        elementConfig: {
          type: "text",
          placeholder: "Tata Play Bank IFSC Code",
          maxLength: INPUT_MAX_LENGTH.LENGTH_TWENTY,
          tabIndex: 0,
        },
        value: "",
        errorMessage: "",
        valid: true,
        touched: false,
      },
    },
  };

  inputChangedHandler = (inputValue, inputIdentifier) => {
    const { selectForm } = this.state;
    const updatedForm = { ...selectForm };
    const updatedFormElement = { ...updatedForm[inputIdentifier] };
    let value = inputValue;
    if (
      inputIdentifier === "partnerCode" ||
      inputIdentifier === "tataSkyBankAccountNumber"
    ) {
      value = value.replace(/[^0-9]/g, "");
    }
    updatedFormElement.value = value;
    updatedFormElement.touched = true;
    updatedFormElement.valid = true;
    updatedFormElement.errorMessage = "";
    updatedForm[inputIdentifier] = updatedFormElement;
    this.setState({ selectForm: updatedForm });
  };

  validateForm = () => {
    const { selectForm } = this.state;
    const validateObj = {};
    const formKeys = Object.keys(selectForm);
    formKeys.forEach((key) => {
      validateObj[key] = selectForm[key].value;
    });
    const formStatus = FormValidator.validateApplicationCodeForm(validateObj);
    const { hasErrors, ...errorMessages } = formStatus;
    if (hasErrors) {
      const updatedForm = { ...selectForm };
      formKeys.forEach((key) => {
        const inputKey = `${key}Message`;
        const errorMessage = errorMessages[inputKey];
        if (errorMessage && errorMessage !== "") {
          updatedForm[key].valid = false;
          updatedForm[key].errorMessage = errorMessage;
        } else {
          updatedForm[key].valid = true;
          updatedForm[key].errorMessage = "";
        }
      });
      this.setState({ selectForm: updatedForm, formErrorMessage: "" });
      return false;
    } else {
      return true;
    }
  };

  formSubmit = async (e) => {
    e.preventDefault();
    const { selectForm } = this.state;
    const validateObj = {};
    for (const key in selectForm) {
      validateObj[key] = selectForm[key].value;
    }
    const validateResponse = this.validateForm(validateObj);
    if (validateResponse) {
      const {
        openModal,
        modalParameters: { buttonInfo, responseObject, resetPage },
      } = this.props;
      const requestObjName = buttonApiObj[buttonInfo.actionCategory];
      const updatedRequestObj = { ...responseObject };
      updatedRequestObj.appliedPartnership.partnerCode =
        validateObj.partnerCode || "";
      updatedRequestObj.appliedPartnership.virtualCode =
        validateObj.virtualCode || "";
      updatedRequestObj.appliedPartnership.tataSkyBankAccountNumber =
        validateObj.tataSkyBankAccountNumber || "";
      updatedRequestObj.appliedPartnership.tataSkyBankName =
        validateObj.tataSkyBankName || "";
      updatedRequestObj.appliedPartnership.tataSkyBankIfscCode =
        validateObj.tataSkyBankIfscCode || "";
      const payload = {
        actionId: buttonInfo.id,
        [requestObjName]: updatedRequestObj,
      };
      this.setState({ isLoading: true });
      try {
        const response = await commonService.partnerChangeState(payload);
        const responseData = interceptedData(response.data);
        if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
          this.setState({
            isLoading: false,
          });
          openModal(MODALS.CONFIRMATION_POPUP, {
            message: buttonInfo.message || responseData.message,
            hideCloseButton: true,
            onCloseCustomFunction: () => resetPage(),
            isCustomCloseFunction: true,
          });
        } else {
          this.setState({
            formErrorMessage: responseData.message,
            isLoading: false,
          });
        }
      } catch {
        this.setState({
          formErrorMessage: GlobalConfig.serverError,
          isLoading: false,
        });
      }
    } else {
      this.setState({ isLoading: false, formErrorMessage: "" });
    }
  };

  render() {
    const { isLoading, selectForm } = this.state;

    let formElementsArray = [];
    const formKeys = Object.keys(selectForm);
    formKeys.forEach((key) => {
      formElementsArray.push({
        id: key,
        config: selectForm[key],
      });
    });

    return (
      <Fragment>
        {isLoading && <Loader />}
        <div className="custom-modal-header pb25">
          <div className="title">Fill these Details</div>
        </div>

        <div className="mr30">
          <form>
            {formElementsArray &&
              formElementsArray.map((formElement) => (
                <Input
                  key={formElement.id}
                  labelConfig={formElement.config.labelConfig}
                  elementType={formElement.config.elementType}
                  elementConfig={formElement.config.elementConfig}
                  value={formElement.config.value}
                  valid={formElement.config.valid}
                  errorMessage={formElement.config.errorMessage}
                  touched={formElement.config.touched}
                  wrapperClasses={formElement.config.wrapperClasses}
                  inputGroupClasses={formElement.config.inputGroupClasses}
                  changed={(event) =>
                    this.inputChangedHandler(event, formElement.id)
                  }
                />
              ))}
          </form>
        </div>

        <div className="bottom-action-btn">
          <button
            className="button full big mb10 action"
            onClick={(e) => this.formSubmit(e)}
            type="button"
          >
            Submit
          </button>
        </div>
      </Fragment>
    );
  }
}


export default connect(null, mapDispatchToProps)(ApplicationCodeViewModal);
