import axios from "axios";
import CryptoJS from 'crypto-js';
import Base64 from "crypto-js/enc-base64";

export const serialize = (obj) => {
  const str = [];
  Object.keys(obj).forEach(p => {
    str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
  });
  /*for (const p in obj) {
    if (obj.hasOwnProperty(p)) {
      str.push(`${encodeURIComponent(p)}=${encodeURIComponent(obj[p])}`);
    }
  }*/
  return str.join('&');
};

export const replaceStringWithParams = (str, obj) => {
  Object.keys(obj).forEach(j => {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  });
  /*for (const j in obj) {
    const regExp = new RegExp(j, 'g');
    str = str.replace(regExp, obj[j]);
  }*/
  return str;
};

export const isAPICanceled = (e) => {
  if(axios.isCancel(e)){
    return false;
  }
};

export const interceptedData =  (data) => {

  // const data = 'fuGxejDnaPmeRVRPeTbIli/rlpgIPwiOVVFqmZH5491Vn6+UGk9ITIP74GJrimGzhibnorj2637cawi1AfvnKhw7VSIcpfcgcP2jsoGYrZI5hkuT91Iv/K19+Bd1Z5+7j04SpDfV+m0n9LZLjIlbEptdq0rdwQaOupYYWpS/Ef42qGgVHxdxDJjb1HqvjNFY1GNpDeG93Ule/LO7gJKCE04pnN1nyAV5p3beHik01DPkDYv/sxCqXfu2BuRlYJVnPNHRtvDNkaAVxIeZJheAV7Ssj+hbxdBpub6uaKekepR0G9vHwClc/OXJKu8Kd+KCKsh41/i4YTfhtnF+3avxE2NCS5DKIoyBkS4fWU1Fz0fNrn++YvBLV1uY7RtfNYC7gRLx5aNf7IHghOqUKcppxronNg33jf0927H/XZuxGXBR7dpD6WkL9koOiXPpC+Axz0/vsyXDBd8A1jduTt7mIsQJu4168T3cZ9NJihnh9TsbIcJ9zJtvNmw8+e+5Ocqjj07IEB2rgdZPkoRIfiUfjVKKz/u+0sZafFQJnLJRwgEZt0vraq1rSmIUXByu2DPC+G9eS+C0u2adj0H0f1P5NdYW/Av2ChZe5bx5fIXu8WcQZLH33WQzq4pRU3xEUgU06bYKvzUQ7KeQQfEg5kz5BQIZRfajM1ho1bD/UIghLk0UXMFaiEm3xoLwrVh2en+emUZY3vflgnJjmjFYP/Vomj/SwvEHBME+qgrZenrj50P3xW6zZSAxNk0QZN5Kb5MNUlwG0ODVD0tuEtxWVrJZUZXGYbcdgeNVZ9rUNuCVAIXVxw8XWOiJxr3zHxull8k1aRg5FT9lE32uogfe4IENn3xT0Di1JGH9LUfv/NzhMMxCZ+X3e1l3RHhxBpbei+574ZMFDPh1VOVdRk10N0SK5D4r/R21xGA4SDOIubgc+J/LkKMVms4t7vE82khd0WWNOKPHqDHs5oAgPu8CHBoD2dQ6IPG/jPcsJNH8MuKknIUCGQjkIrmPjIVNiMkC/d1SiWd256inHseOW/vK8RGUyKFxLzyDdVl6+TrJANx5wKxVBAoq+YnrGfpjR/dxW9uu4wgBpnGy9KdM0xF5hKsrPa0AERs+L72JFeqgH9irlZVXaUZlP1blpBiYqNHB4ElQjf5Nw8jlKnb2UlrK4tS2VaB77y86Tjj6s+7MMKf08Jg/APpb4U+hJC++ia6lc4E7wrvQZgQHNrZA2ixxYM7HVHD9t3by4cKeoBscl/qKoGgxsoVqGVbqja4l8uRmcNrnvsTRuKLMcguhyk5o/uMOGpI+jHyl1UqgbTefhA6seSbKPzbMaY5UEhc3qrTCDYzh9NPZlC3m/fU1EApadD9OMMvHqRhhdh9BaRQSJCCT6ybA0PAPaT3iAA9QhWWvnhN8nbELumGwkroO1MXhO7RWILxY7BQWi0M58DcLdXN9jX9IiDsXDMH5nKidwn+oySHUvs1WEv843qDY2f7LtMElqzbTyOZ8A2Nc3qRU01ThiEoCtu+7YHrqvGMdIC61k471wb5X1okfpWvPShOS0pJ1acoi3uTY1AzoDEp27RAlc+sbIBka5YcR1am6DdeMiCX3Myx+gIKkbxvbMvuni0aCl933ZQ5PPTRrKehIYD33l0kHrm8avNLJURUifNHhLjWVw51KojweRGeyEu/zXb+oKAbDd5aWzFLGscDU5nQlA5dR+H6feh5u2ASO8L8spJd0MSAwmAAq60SnZwtp63hpQuaQGUN28xgyd1JXQCC70aNcnG4p5MP0L4yIcyQ9gqAA2U9BaNQjVrcx4bxORu35+1T51952vzVspilt7J4uL9csYF7pPnlm6zFlqgVKd4cIeUiqUMs4AWHBJNdaYFC9Zw=='
try {
  // Decode the base64 string
  const encryptedBytes = Base64.parse(data);

  // Extract IV from the beginning of the encrypted data
  const iv = CryptoJS.lib.WordArray.create(
    encryptedBytes.words.slice(0, 4)
  );

  // Extract the actual encrypted data
  const encryptedValue = CryptoJS.lib.WordArray.create(
    encryptedBytes.words.slice(4)
  );

  // Convert hexadecimal key string to WordArray
  const key = CryptoJS.enc.Hex.parse('564C68347365755665454B4C4D4E4F50');
  // Decrypt the data
  const decrypted = CryptoJS.AES.decrypt(
    { ciphertext: encryptedValue },
    key,
    { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
  );

  // Attempt to parse decrypted data as JSON
  let decryptedText = decrypted.toString(CryptoJS.enc.Utf8);
  try {
    decryptedText = JSON.parse(decryptedText); // Try parsing as JSON
  } catch (e) {
    // Handle parsing error (could be due to non-JSON data)
    console.error("Parsing JSON error:", e);
  }
  return decryptedText
} catch (e) {
  console.error("Decryption error: ", e);
}

};


