import React, {Component, Fragment} from 'react';
import Loader from "../../../Loader";
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import Input from "../../../Common/Input";
import {INPUT_MAX_LENGTH} from "../../../../constants";
import {MODALS} from "../../../../storeManager/modal/constants";
import FormValidator from "../../../../services/FormValidator";
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import {bindActionCreators} from "redux";
import {connect} from "react-redux";
import {buttonApiObj} from "../../helper";
import _get from 'lodash/get';
import { interceptedData } from '../../../../services/commonUtils';

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
});


class ReassignModal extends Component {
    state = {
        isLoading: false,
        selectForm: {
            comment: {
                wrapperClasses: ["form-element textarea-field"],
                labelConfig: {
                    label: "Comment",
                    classes: ["label-title"]
                },
                elementType: "textarea",
                elementConfig: {
                    placeholder: "Comment here",
                    maxLength: INPUT_MAX_LENGTH.LENGTH_TWO_FIFTY,
                    tabIndex: 0
                },
                value: "",
                errorMessage: "",
                valid: true,
                touched: false
            },
        }
    };

    inputChangedHandler = (inputValue, inputIdentifier) => {
        const { selectForm } = this.state;
        const updatedForm = { ...selectForm };
        const updatedFormElement = { ...updatedForm[inputIdentifier] };
        updatedFormElement.touched = true;
        updatedFormElement.valid = true;
        updatedFormElement.value = inputValue;
        updatedFormElement.errorMessage = "";
        updatedForm[inputIdentifier] = updatedFormElement;
        this.setState({ selectForm: updatedForm });
    };

    validateForm = () => {
        const { selectForm } = this.state;
        const validateObj = {};
        const formKeys = Object.keys(selectForm);
        formKeys.forEach(key => {
            validateObj[key] = selectForm[key].value;
        });
        const formStatus = FormValidator.validateDashboardCommentForm(validateObj);
        const { hasErrors, ...errorMessages } = formStatus;
        if (hasErrors) {
            const updatedForm = { ...selectForm };
            formKeys.forEach(key => {
                const inputKey = `${key}Message`;
                const errorMessage = errorMessages[inputKey];
                if (errorMessage && errorMessage !== "") {
                    updatedForm[key].valid = false;
                    updatedForm[key].errorMessage = errorMessage;
                }
                else {
                    updatedForm[key].valid = true;
                    updatedForm[key].errorMessage = '';
                }
            });
            this.setState({ selectForm: updatedForm, formErrorMessage: "" });
            return false;
        } else {
            return true;
        }
    };

    formSubmit = async (e) => {
        e.preventDefault();
        const { selectForm } = this.state;
        const validateObj = {};
        for (const key in selectForm) {
            validateObj[key] = selectForm[key].value;
        }
        const validateResponse = this.validateForm(validateObj);
        if (validateResponse) {
            const { openModal, modalParameters: { buttonInfo, responseObject, resetPage } } = this.props;
            const requestObjName = buttonApiObj[buttonInfo.actionCategory];
            const payload = {
                "actionId": buttonInfo.id,
                "comment": validateObj.comment || '',
                // "personId": validateObj.assignee || 0,
                [requestObjName]: requestObjName === 'partner' ? responseObject : responseObject[buttonApiObj[requestObjName]],
            };
            try {
                this.setState({isLoading: true})
                const response = await commonService.partnerChangeState(payload);
                const responseData = interceptedData(response.data);
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    this.setState({
                        isLoading: false
                    });
                    openModal(MODALS.CONFIRMATION_POPUP, {
                        message: buttonInfo.message || responseData.message,
                        hideCloseButton: true,
                        onCloseCustomFunction: () => resetPage(),
                        isCustomCloseFunction: true
                    });
                } else {
                    this.setState({
                        formErrorMessage: responseData.message,
                        isLoading: false
                    });
                }
            } catch {
                this.setState({
                    formErrorMessage: GlobalConfig.serverError,
                    isLoading: false
                });
            }
        } else {
            this.setState({isLoading: false, formErrorMessage:""});
        }
    }

    render() {
        const {isLoading, selectForm} = this.state;
        const { closePopup,
            //  modalParameters: { responseObject } 
            } = this.props;
        const circleCode = _get(this.props.modalParameters.responseObject, 'firm.address.town.circleCode.name', '')
        let formElementsArray = [];
        const formKeys = Object.keys(selectForm);
        formKeys.forEach(key => {
            formElementsArray.push({
                id: key,
                config: selectForm[key]
            });
        });
 

        return (
            <Fragment>
                {
                    isLoading && <Loader />
                }
                <div className="custom-modal-header pb25">
                    <div className="title">
                        <br />This is an administrative action. By confirming this the ticket will move to "{circleCode}" and will get assigned to the concerned CSM. Are you sure you want to proceed?
                    </div>
                </div>

                <div className="mr30">
                    <form
                    >
                        {formElementsArray &&
                        formElementsArray.map(formElement => (
                            <Input
                                key={formElement.id}
                                labelConfig={formElement.config.labelConfig}
                                elementType={formElement.config.elementType}
                                elementConfig={formElement.config.elementConfig}
                                // value={(headerValues && headerValues[formElement.config.value]) || ''}
                                valid={formElement.config.valid}
                                errorMessage={formElement.config.errorMessage}
                                touched={formElement.config.touched}
                                wrapperClasses={formElement.config.wrapperClasses}
                                inputGroupClasses={formElement.config.inputGroupClasses}
                                changed={event => this.inputChangedHandler(event, formElement.id)}
                            />
                        ))}
                    </form>
                </div>

                <div className="bottom-action-btn">
                    <button className="button full big mb10 fill" onClick={(e) => this.formSubmit(e)} type="button">
                        YES
                    </button>
                    <button type="button" className="button full big mb10 action" onClick={(e) => closePopup()} type="button">
                        NO
                    </button>
                </div>
            </Fragment>
        );
    }
}


export default connect(null, mapDispatchToProps)(ReassignModal);
