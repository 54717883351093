import React, {Component} from 'react';
import { withRouter } from 'react-router-dom';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {PATHS} from "../../../../constants";
import userImage from '../../../../assets/images/user.png';
import notificationIcon from '../../../../assets/images/notification.png';
import './profile.scss';
import Loader from "../../../Loader";
import {useMediaQuery} from 'react-responsive';
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import {MODALS} from "../../../../storeManager/modal/constants";
import {setNotificationsList} from "../../../../storeManager/common/actions";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import { commonService } from "../../../../storeManager/common/services";
import {dateFormatter, isAuthenticated} from '../../../../helper';
// import Modal from '../../Modal';
import GlobalSearch from '../../GlobalSearch';
import _isEmpty from 'lodash/isEmpty';
import { interceptedData } from '../../../../services/commonUtils';


const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch),
    setNotificationsList: bindActionCreators(setNotificationsList, dispatch)
});

const mapStateToProps = state => ({
    notificationList: state.commonReducer.notificationList,
  });

const Desktop = ({ children }) => {
    const isDesktop = useMediaQuery({ minWidth: 768 });
    return isDesktop ? children : null
};

const Mobile = ({ children }) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null
};

class Profile extends Component {
    userDropdownContainer = React.createRef();
    state = {
        userDropdown: false,
        isLoading: false,
        role: JSON.parse(localStorage.getItem('role')) || {},
        name: localStorage.getItem('name') || 'User',
    };

    componentDidMount() {
        document.addEventListener("mousedown", this.handleClickOutside);
        if (isAuthenticated()) {
            this.getNotifications();
        }
    }

    componentWillUnmount() {
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    handleClickOutside = event => {
        if (this.userDropdownContainer.current && !this.userDropdownContainer.current.contains(event.target)) {
            this.setState({
                userDropdown: false,
            });
        }
    };

    toggleUserDropdown = (event) => {
        const {userDropdown} = this.state;
        this.setState({
            userDropdown: !userDropdown
        });
    };

    signOut = async () => {
        this.setState({isLoading: true});
        const {history} = this.props;
        try {
            const response = await commonService.userLogout();
            const responseData = response.data;
            if (responseData.status) {
                localStorage.clear();
                history.push(PATHS.LOGIN);
            }
        } catch(err) {
            localStorage.clear();
            history.push(PATHS.LOGIN);
        }
    };

    unreadNotifications = notificationList => {
        let totalUnread = 0;
        if (notificationList && notificationList.length) {
          totalUnread = notificationList.filter(item => item.isNew).length;
        }
        return totalUnread;
      };

      setInitialNotification = (notificationList) => {
          let updatedNotificationList = notificationList.map(item => {
              return{
                id: item.id || '',
                date: (item.createdDate && dateFormatter(item.createdDate, 'dd/MMM/yyyy')) || '',
                requestId: item.applicationId || '',
                comment: item.comment || '',
                role: (item.assignedTo && item.assignedTo.approverAuthority && item.assignedTo.approverAuthority.role && item.assignedTo.approverAuthority.role.description) || '',
                isNew: !item.viewed || false,
              }
          });
          this.props.setNotificationsList(updatedNotificationList)

      }

      getNotifications = async () => {
        this.setState({ isLoading: true, });
        const {setNotificationsList} = this.props;
        const loggedInUserRole = JSON.parse(localStorage.getItem("role"));
        const roleId = (loggedInUserRole && loggedInUserRole.id) || "";
        const payload = {
          id: roleId
        };
        try {
          const response = await commonService.notificationList(payload);
          const responseData = interceptedData(response.data);
          if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
              this.setInitialNotification(responseData.data || []);
            this.setState({ isLoading: false,});
        } else {
            this.setState({ error: responseData.message, isLoading: false,});
            setNotificationsList([]);
        }
    } catch {
        this.setState({ error: GlobalConfig.serverError, isLoading: false, });
        setNotificationsList([]);
        }
      };
  
      changeNotificationStatus = async (notificationId) => {
        const payload = {
            notificationId
        };
        try {
          const response = await commonService.changeNotificationStatus(payload);
        }
        catch(e) {
            console.log("error", e)
                }
      };



    render() {
        const {openModal, notificationList} = this.props;
      const { userDropdown, isLoading, role, name, searchProfileList, isLoadingBar } = this.state;
        const {toggleUserDropdown, signOut, changeNotificationStatus} = this;
        const unreadNotifications = this.unreadNotifications(notificationList);

        return (
          <div
            className="userDropdown-container"
            ref={this.userDropdownContainer}
          >
            <GlobalSearch isLoadingBar={isLoadingBar} allProfileList={searchProfileList} getProfileList={this.getProfileList}/>
            <Desktop>
              <div className="user-info">
                {isLoading && <Loader />}
                <div
                  className={`notification update  ${
                    unreadNotifications > 0 ? "unread-notication" : ""
                  }`}
                >
                  <img
                    src={notificationIcon}
                    alt="notify"
                    title="Latest Notification"
                    onClick={() =>
                      openModal(MODALS.NOTIFICATION_MODAL, {
                        hideCloseButton: false,
                        customModalClass: "overlay",
                        customModalContentClass:
                          "right-view-modal notification",
                        changeNotificationStatus: changeNotificationStatus,
                      })
                    }
                  />
                </div>

                <div className="user-img">
                  <img src={userImage} alt="User" className="mr10" />
                </div>
                <div
                  className={userDropdown ? "detail open" : "detail"}
                  onClick={toggleUserDropdown}
                >
                  <div className="greeting">{role.description || "Role"}</div>
                  <div className="name">{name}</div>
                  <i className="icon">
                    <svg width="7" height="4" viewBox="0 0 13 7">
                      <path
                        fill="#354060"
                        fillRule="evenodd"
                        d="M.189-.003H12.8a.688.688 0 010 .972l-5.82 5.83a.686.686 0 01-.97 0L.189.969a.69.69 0 010-.972z"
                      />
                    </svg>
                  </i>

                  <ul className="user-dropdown">
                    <li className="option" onClick={signOut}>
                      <i className="signout-icon">
                        <svg width="17" height="13" viewBox="0 0 34 25">
                          <defs>
                            <linearGradient id="logout" x1="0%" y1="0%" y2="0%">
                              <stop offset="0%" stopColor="#e8308f" />
                              <stop offset="35%" stopColor="#e8308f" />
                              <stop offset="80%" stopColor="#e8308f" />
                              <stop offset="100%" stopColor="#e8308f" />
                            </linearGradient>
                          </defs>
                          <path
                            fillRule="evenodd"
                            d="M21.358 25c-4.206 0-8.125-2.06-10.482-5.51a1.517 1.517 0 01.416-2.122 1.556 1.556 0 012.145.411 9.58 9.58 0 007.921 4.165c5.267 0 9.551-4.237 9.551-9.444 0-5.207-4.284-9.444-9.551-9.444a9.577 9.577 0 00-7.934 4.184c-.476.7-1.436.886-2.144.416a1.518 1.518 0 01-.421-2.121A12.672 12.672 0 0121.358 0C28.329 0 34 5.607 34 12.5S28.329 25 21.358 25zM5.277 10.972h17.905c.853 0 1.545.684 1.545 1.528s-.692 1.528-1.545 1.528H5.277l1.998 1.976a1.517 1.517 0 010 2.161 1.552 1.552 0 01-1.093.447 1.55 1.55 0 01-1.093-.447L.453 13.581a2.127 2.127 0 01-.102-.111l-.022-.029c-.023-.03-.047-.06-.068-.091l-.017-.028c-.021-.033-.042-.066-.061-.101a1.341 1.341 0 01-.065-.135l-.005-.014a1.484 1.484 0 01-.089-.309c-.006-.037-.013-.073-.016-.11a1.568 1.568 0 010-.305c.003-.037.01-.073.016-.109l.006-.04c.009-.043.021-.086.033-.128l.004-.016a1.35 1.35 0 01.046-.126l.005-.015a1.195 1.195 0 01.065-.135 1.69 1.69 0 01.061-.101l.017-.028c.021-.031.045-.061.068-.09l.022-.03c.033-.038.066-.075.102-.111l4.636-4.584a1.558 1.558 0 012.186 0 1.517 1.517 0 010 2.161l-1.998 1.976z"
                          />
                          <path
                            fill="#e8308f"
                            d="M21.358 25c-4.206 0-8.125-2.06-10.482-5.51a1.517 1.517 0 01.416-2.122 1.556 1.556 0 012.145.411 9.58 9.58 0 007.921 4.165c5.267 0 9.551-4.237 9.551-9.444 0-5.207-4.284-9.444-9.551-9.444a9.577 9.577 0 00-7.934 4.184c-.476.7-1.436.886-2.144.416a1.518 1.518 0 01-.421-2.121A12.672 12.672 0 0121.358 0C28.329 0 34 5.607 34 12.5S28.329 25 21.358 25zM5.277 10.972h17.905c.853 0 1.545.684 1.545 1.528s-.692 1.528-1.545 1.528H5.277l1.998 1.976a1.517 1.517 0 010 2.161 1.552 1.552 0 01-1.093.447 1.55 1.55 0 01-1.093-.447L.453 13.581a2.127 2.127 0 01-.102-.111l-.022-.029c-.023-.03-.047-.06-.068-.091l-.017-.028c-.021-.033-.042-.066-.061-.101a1.341 1.341 0 01-.065-.135l-.005-.014a1.484 1.484 0 01-.089-.309c-.006-.037-.013-.073-.016-.11a1.568 1.568 0 010-.305c.003-.037.01-.073.016-.109l.006-.04c.009-.043.021-.086.033-.128l.004-.016a1.35 1.35 0 01.046-.126l.005-.015a1.195 1.195 0 01.065-.135 1.69 1.69 0 01.061-.101l.017-.028c.021-.031.045-.061.068-.09l.022-.03c.033-.038.066-.075.102-.111l4.636-4.584a1.558 1.558 0 012.186 0 1.517 1.517 0 010 2.161l-1.998 1.976z"
                          />
                        </svg>
                      </i>
                      Signout
                    </li>
                  </ul>
                </div>
              </div>
            </Desktop>
            <Mobile>
              <div className="user-info">
                {isLoading && <Loader />}
                <div
                  className={`notification update ${
                    unreadNotifications > 0 ? "unread-notication" : ""
                  }`}
                >
                  <img
                    src={notificationIcon}
                    alt="notify"
                    title="Latest Notification"
                    onClick={() =>
                      openModal(MODALS.NOTIFICATION_MODAL, {
                        hideCloseButton: false,
                        customModalClass: "overlay",
                        customModalContentClass:
                          "right-view-modal notification",
                        changeNotificationStatus: changeNotificationStatus,
                      })
                    }
                  />
                </div>

                <div className="user-img">
                  <img src={userImage} alt="User" className="mr10" />
                </div>
                <div
                  className={userDropdown ? "detail open" : "detail"}
                  onClick={toggleUserDropdown}
                >
                  <i className="icon">
                    <svg width="7" height="4" viewBox="0 0 13 7">
                      <path
                        fill="#354060"
                        fillRule="evenodd"
                        d="M.189-.003H12.8a.688.688 0 010 .972l-5.82 5.83a.686.686 0 01-.97 0L.189.969a.69.69 0 010-.972z"
                      />
                    </svg>
                  </i>

                  <ul className="user-dropdown">
                    <li className="option">
                      <div className="greeting">
                        {role.description || "Role"}
                      </div>
                      <div className="name pl10">{name}</div>
                    </li>

                    <li className="option" onClick={signOut}>
                      Signout
                      <i className="signout-icon">
                        <svg width="17" height="13" viewBox="0 0 34 25">
                          <defs>
                            <linearGradient id="logout" x1="0%" y1="0%" y2="0%">
                              <stop offset="0%" stopColor="#e8308f" />
                              <stop offset="35%" stopColor="#e8308f" />
                              <stop offset="80%" stopColor="#e8308f" />
                              <stop offset="100%" stopColor="#e8308f" />
                            </linearGradient>
                          </defs>
                          <path
                            fillRule="evenodd"
                            d="M21.358 25c-4.206 0-8.125-2.06-10.482-5.51a1.517 1.517 0 01.416-2.122 1.556 1.556 0 012.145.411 9.58 9.58 0 007.921 4.165c5.267 0 9.551-4.237 9.551-9.444 0-5.207-4.284-9.444-9.551-9.444a9.577 9.577 0 00-7.934 4.184c-.476.7-1.436.886-2.144.416a1.518 1.518 0 01-.421-2.121A12.672 12.672 0 0121.358 0C28.329 0 34 5.607 34 12.5S28.329 25 21.358 25zM5.277 10.972h17.905c.853 0 1.545.684 1.545 1.528s-.692 1.528-1.545 1.528H5.277l1.998 1.976a1.517 1.517 0 010 2.161 1.552 1.552 0 01-1.093.447 1.55 1.55 0 01-1.093-.447L.453 13.581a2.127 2.127 0 01-.102-.111l-.022-.029c-.023-.03-.047-.06-.068-.091l-.017-.028c-.021-.033-.042-.066-.061-.101a1.341 1.341 0 01-.065-.135l-.005-.014a1.484 1.484 0 01-.089-.309c-.006-.037-.013-.073-.016-.11a1.568 1.568 0 010-.305c.003-.037.01-.073.016-.109l.006-.04c.009-.043.021-.086.033-.128l.004-.016a1.35 1.35 0 01.046-.126l.005-.015a1.195 1.195 0 01.065-.135 1.69 1.69 0 01.061-.101l.017-.028c.021-.031.045-.061.068-.09l.022-.03c.033-.038.066-.075.102-.111l4.636-4.584a1.558 1.558 0 012.186 0 1.517 1.517 0 010 2.161l-1.998 1.976z"
                          />
                          <path
                            fill="#e8308f"
                            d="M21.358 25c-4.206 0-8.125-2.06-10.482-5.51a1.517 1.517 0 01.416-2.122 1.556 1.556 0 012.145.411 9.58 9.58 0 007.921 4.165c5.267 0 9.551-4.237 9.551-9.444 0-5.207-4.284-9.444-9.551-9.444a9.577 9.577 0 00-7.934 4.184c-.476.7-1.436.886-2.144.416a1.518 1.518 0 01-.421-2.121A12.672 12.672 0 0121.358 0C28.329 0 34 5.607 34 12.5S28.329 25 21.358 25zM5.277 10.972h17.905c.853 0 1.545.684 1.545 1.528s-.692 1.528-1.545 1.528H5.277l1.998 1.976a1.517 1.517 0 010 2.161 1.552 1.552 0 01-1.093.447 1.55 1.55 0 01-1.093-.447L.453 13.581a2.127 2.127 0 01-.102-.111l-.022-.029c-.023-.03-.047-.06-.068-.091l-.017-.028c-.021-.033-.042-.066-.061-.101a1.341 1.341 0 01-.065-.135l-.005-.014a1.484 1.484 0 01-.089-.309c-.006-.037-.013-.073-.016-.11a1.568 1.568 0 010-.305c.003-.037.01-.073.016-.109l.006-.04c.009-.043.021-.086.033-.128l.004-.016a1.35 1.35 0 01.046-.126l.005-.015a1.195 1.195 0 01.065-.135 1.69 1.69 0 01.061-.101l.017-.028c.021-.031.045-.061.068-.09l.022-.03c.033-.038.066-.075.102-.111l4.636-4.584a1.558 1.558 0 012.186 0 1.517 1.517 0 010 2.161l-1.998 1.976z"
                          />
                        </svg>
                      </i>
                    </li>
                  </ul>
                </div>
              </div>
            </Mobile>
            {/*<Modal />*/}
          </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Profile));
