import React, { Component, Fragment } from 'react';
import Loader from "../../../Loader";
import {buttonApiObj} from "../../helper";
import {commonService} from "../../../../storeManager/common/services";
import GlobalConfig from "../../../../globalConfig/globalConfig";
import {MODALS} from "../../../../storeManager/modal/constants";
import TextArea from "../../../Admin/TextArea";
import {bindActionCreators} from "redux";
import {openModal as openModalHandler} from "../../../../storeManager/modal/actions";
import {connect} from "react-redux";
import {INPUT_ERROR} from "../../../../constants";
import { interceptedData } from '../../../../services/commonUtils';

const mapDispatchToProps = dispatch => ({
    openModal: bindActionCreators(openModalHandler, dispatch)
});

class DocumentViewModal extends Component {
    state = {
        isLoading: false,
        partnerDocument : this.props.modalParameters &&
        this.props.modalParameters.responseObject && this.props.modalParameters.responseObject.partnerDocument,
    };

    handleChange = (docIndex, name, value) => {
        const { partnerDocument } = this.state;
        const updatedPartnerDocument = {...partnerDocument};
        if(updatedPartnerDocument && updatedPartnerDocument.uploadedDocuments && updatedPartnerDocument.uploadedDocuments.length) {
            updatedPartnerDocument.uploadedDocuments[docIndex].comment = value;
        }
        this.setState({
            partnerDocument: updatedPartnerDocument,
        })
    };

    validateForm = () => {
        const { partnerDocument } = this.state;
        const updatedPartnerDocument = {...partnerDocument};
        let error = false;
        if(updatedPartnerDocument && updatedPartnerDocument.uploadedDocuments && updatedPartnerDocument.uploadedDocuments.length) {
            updatedPartnerDocument.uploadedDocuments.forEach((item, index) => {
                if(item.documentStatus === 'REJECT') {
                    if(!item.comment) {
                        error = true;
                        item.ErrorComment = INPUT_ERROR.REQUIRED;
                    } else {
                        item.ErrorComment = "";
                    }
                }
            });
        }
        this.setState({partnerDocument: updatedPartnerDocument});
        if(error) {
            return false;
        } else {
            return true;
        }
    };

    formSubmit = async (e, buttonInfo) => {
        e.preventDefault();
        const validateResponse = this.validateForm();
        if (validateResponse) {
            const { partnerDocument } = this.state;
            const { openModal, modalParameters: { resetPage } } = this.props;
            const requestObjName = buttonApiObj[buttonInfo.actionCategory];
            const payload = {
                "actionId": buttonInfo.id,
                partnerDocument: partnerDocument,
            };

            try {
                this.setState({isLoading: true})
                const response = await commonService.partnerChangeState(payload);
                const responseData = interceptedData(response.data);
                if (responseData.status === GlobalConfig.MESSAGES_TYPES.TRUE) {
                    this.setState({
                        isLoading: false
                    });
                    openModal(MODALS.CONFIRMATION_POPUP, {
                        message: buttonInfo.message || responseData.message,
                        hideCloseButton: true,
                        onCloseCustomFunction: () => resetPage(),
                        isCustomCloseFunction: true
                    });
                } else {
                    this.setState({
                        formErrorMessage: responseData.message,
                        isLoading: false
                    });
                }
            } catch {
                this.setState({
                    formErrorMessage: GlobalConfig.serverError,
                    isLoading: false
                });
            }
        } else {
            this.setState({isLoading: false, formErrorMessage:""});
        }
    }

    getRejectApproveCount = () => {
        const { partnerDocument } = this.state;
        let rejectCount = 0, approvedCount = 0, isApproved = false, selectedCount= 0, isAllSelected= false;
        if(partnerDocument && partnerDocument.uploadedDocuments && partnerDocument.uploadedDocuments.length) {
            partnerDocument.uploadedDocuments.map((item, index) => {
                if(item.documentStatus === 'REJECT') {
                    rejectCount += 1;
                    selectedCount += 1;
                }
                if(item.documentStatus === 'APRROVED') {
                    approvedCount += 1;
                    selectedCount += 1;
                }
            });
            isApproved = (approvedCount === partnerDocument.uploadedDocuments.length);
            isAllSelected = (selectedCount === partnerDocument.uploadedDocuments.length);
        }
        return {
            rejectCount,
            isApproved,
            isAllSelected
        };
    }

    pendingDocCount = () => {
        const {partnerDocument} = this.state;
        let pendingDocCount = 0;
        if(partnerDocument && partnerDocument.uploadedDocuments && partnerDocument.uploadedDocuments.length) {
            partnerDocument.uploadedDocuments.map((item, index) => {
                if(item.documentStatus !== 'APRROVED') {
                    pendingDocCount += 1;
                }
            });
        }
        return pendingDocCount;
    }

    statusChangeHandler = (statusType, index) => {
        const {partnerDocument} = this.state;
        const updatedPartnerDocument = {...partnerDocument};

        updatedPartnerDocument.uploadedDocuments[index].documentStatus = statusType;
        if(statusType === 'APRROVED'){
            updatedPartnerDocument.uploadedDocuments[index].comment = '';
        }

        this.setState({partnerDocument: updatedPartnerDocument});
    }
 downloadDocument = async (file) => {
        // let documentUrls = [];
        // const {uploadedDocuments} = this.props;
        // const findDocument = uploadedDocuments.find(item => item.id === id);
        // if (findDocument) {
        //     documentUrls = findDocument.documents;
        // }
        // documentUrls && documentUrls.forEach((item) => {
            commonService.downloadFile({fileUrl: file.url})
                .then(res => {
                    const binaryImg = atob(res.data);
                    const length = binaryImg.length;
                    const arrayBuffer = new ArrayBuffer(length);
                    const uintArray = new Uint8Array(arrayBuffer);
                    for (let i = 0; i < length; i++) {
                        uintArray[i] = binaryImg.charCodeAt(i);
                    }
                    const fileBlob = new Blob([uintArray], { type: 'application/pdf' });
                    const link=document.createElement('a');
                    link.href=window.URL.createObjectURL(fileBlob);
                    link.download=file.name;
                     link.click();
                    })
                // });
    };
    render() {
        const { isLoading, partnerDocument } = this.state;
        const { modalParameters } = this.props;
        const rejectApproveCount = this.getRejectApproveCount();
        const pendingDocuments = this.pendingDocCount();
        const { downloadDocument } = this;
        return (
            <Fragment>
                {
                    isLoading && <Loader />
                }
                <div className="custom-modal-header pb25">
                    <div className="title">
                        VERIFY DOCUMENTS
                    </div>

                    <div className="sub-title">
                        Pending
                        <span className="pending prl5">
                            {pendingDocuments}
                        </span>
                        / {partnerDocument && partnerDocument.uploadedDocuments.length} Document{`${partnerDocument.uploadedDocuments.length > 1 ? 's' : ''}`}
                    </div>
                </div>
                <div className="document-list-container">

                    {
                        (partnerDocument && partnerDocument.uploadedDocuments && partnerDocument.uploadedDocuments.length) ?
                            partnerDocument.uploadedDocuments.map((item, index) => {
                                let gstOperationalText = [];
                                return (
                                    <div key={index} className="document-type">
                                        <div className="label serial pt25">
                                            {index < 9 ? '0' : ''}
                                            {`${(index+1)}`}
                                        </div>
                                        <div className="dashed-border">
                                            <div className="content">
                                                <div>
                                                    <div className="label pb15">
                                                        {item.documentTemplate && item.documentTemplate.label}
                                                    </div>
                                                    {item.rightSectionCheckBox && item.documentTemplate && item.documentTemplate.rightSection && item.documentTemplate.rightSection.templateUrls && item.documentTemplate.rightSection.templateUrls.length
                                                    &&
                                                    <div className="declaration-info">
                                                        Self declaration submitted
                                                        {/*<a
                                                            href={item.documentTemplate.rightSection.templateUrls[0].templateUrl || ''}
                                                            target="_blank" className="declaration-info"
                                                        >
                                                            {item.documentTemplate.rightSection.text}
                                                        </a>*/}
                                                    </div>
                                                    }
                                                    {
                                                        item.documentTemplate && item.documentTemplate.templateTypeKeys && item.documentTemplate.templateTypeKeys.length
                                                        && item.documentTemplate.templateTypeKeys.forEach(templateItem => {
                                                            templateItem.radioKeys && templateItem.radioKeys.length &&
                                                            templateItem.radioKeys.forEach(radioItem => {
                                                                if(radioItem.radioKeyType === 'FALSE' && radioItem.textShow){
                                                                    gstOperationalText.push(<div className="declaration-info">{radioItem.textShow}</div>)
                                                                }
                                                            })
                                                        })
                                                        || null
                                                    }
                                                    {gstOperationalText}
                                                    {
                                                        (item.documents && item.documents.length) ?
                                                            <ul className="doc-list">
                                                                {
                                                                    item.documents.map((item, index) => {
                                                                        return (
                                                                            <a 
                                                                            href="javascript:void(0)"
                                                                            key={index} 
                                                                            rel="noopener noreferrer" 
                                                                            // target="_blank"
                                                                            onClick={() => downloadDocument(item)}
                                                                            >
                                                                                <li >{item.name}</li>
                                                                            </a>
                                                                        )
                                                                    })
                                                                }
                                                            </ul>
                                                            : null

                                                    }
                                                </div>
                                                <div className="action-btn-group">
                                                    <button
                                                        onClick={() => {this.statusChangeHandler('APRROVED', index);}}
                                                        type="button"
                                                        className={item.documentStatus === 'APRROVED' ? 'button approved' : 'button'}>
                                                        <i className="icon-circle">
                                                            {
                                                                (item.documentStatus === 'APRROVED') ?
                                                                    <svg width="14" height="10" viewBox="0 0 28 20">
                                                                        <path fill="#0DC530" fillRule="evenodd" d="M24.695.554L9.465 15.432 3.308 9.419a1.97 1.97 0 00-2.739 0 1.862 1.862 0 000 2.677l7.526 7.351c.379.369.874.553 1.37.553.495 0 .992-.184 1.37-.554l.004-.005L27.434 3.229a1.86 1.86 0 000-2.675 1.97 1.97 0 00-2.739 0z" />
                                                                    </svg>
                                                                    :
                                                                    null
                                                            }
                                                        </i>
                                                        Approve
                                                    </button>
                                                    <button
                                                        onClick={() => {this.statusChangeHandler('REJECT', index);}}
                                                        type="button"
                                                        className={item.documentStatus === 'REJECT' ? 'button reject' : 'button'}>
                                                        <i className="icon-circle">
                                                            {
                                                                (item.documentStatus === 'REJECT') ?
                                                                    <svg width="11" height="10" viewBox="0 0 21 20">
                                                                        <path fill="#F12043" fillRule="evenodd" d="M13.709 9.799l5.74 5.633c2.335 2.346 1.362 4.015 1.362 4.015a1.94 1.94 0 01-1.362.554 1.94 1.94 0 01-1.364-.555l-.004-.005-7.104-6.973-7.138 6.973-.004.005c-.378.37-.875.555-1.37.555-.496 0-.991-.185-1.37-.554 0 0-.976-1.669 1.37-4.015l5.779-5.645-6.681-6.558a1.866 1.866 0 010-2.675 1.953 1.953 0 012.726 0l6.688 6.563L17.695.554a1.97 1.97 0 012.739 0 1.86 1.86 0 010 2.675l-6.725 6.57z" />
                                                                    </svg>
                                                                    :
                                                                    null
                                                            }
                                                        </i>
                                                        Reject
                                                    </button>
                                                </div>
                                            </div>
                                            {
                                                (item.documentStatus === 'REJECT') ?
                                                    <div className="form-element textarea-field ml15">
                                                        <TextArea
                                                            errorsMessage={item.ErrorComment || ''}
                                                            labelName='Reason for rejection'
                                                            name='comment'
                                                            className='input-element rejection-comment'
                                                            pholder="rejection reason"
                                                            onChange={(name, value) => this.handleChange(index, name, value)}
                                                            value={item.comment || ''}
                                                        />
                                                    </div>
                                                    :
                                                    null
                                            }
                                        </div>
                                    </div>
                                )
                            })

                            : null
                    }
                </div>
                {
                    (modalParameters && modalParameters.filteredAllAction) ?
                        <div className="bottom-action-btn">
                            {
                                modalParameters.filteredAllAction.map((item, index) => {
                                    return (
                                        (item.priority === 'PRIMARY') ?
                                            <button
                                                key={index}
                                                onClick={(e) => this.formSubmit(e, item)}
                                                className="button big action"
                                                type="button"
                                                disabled={!rejectApproveCount.isApproved}
                                            >
                                                {item.label}
                                            </button>
                                            :
                                            <button
                                                key={index}
                                                onClick={(e) => this.formSubmit(e, item)}
                                                className="button big fill"
                                                type="button"
                                                disabled={!(rejectApproveCount.isAllSelected && rejectApproveCount.rejectCount > 0)}
                                            >
                                                {item.label}
                                            </button>

                                    )
                                })
                            }
                        </div>
                        :
                        null
                }

            </Fragment>
        );
    }
}

export default connect(null, mapDispatchToProps)(DocumentViewModal);
